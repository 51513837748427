// Set up the base URL for the API
const baseUrl = 'https://ois-ai-models-app.azurewebsites.net/api';

/**
 * Sends a request to the API with error handling.
 * @param {string} url - The URL for the API endpoint.
 * @param {object} options - Options for the API request (e.g., method, body, headers).
 * @param {string} type - The type parameter to be appended to the URL or included in the request body.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
const callApi = async (url, options, type) => {
  try {
    let newUrl = url;

    if (options.method === 'GET' && type) {
      newUrl += `?type=${type}`;
    } else if (options.body instanceof FormData && options.body.has('files')) {
      options.body.append('type', type);
    } else {
      const bodyData = options.body ? JSON.parse(options.body) : {};
      const newData = { ...bodyData, type };
      options.body = JSON.stringify(newData);
    }

    const response = await fetch(newUrl, options);

    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${newUrl}`);
    }

    return response.json();
  } catch (error) {
    throw new Error(`Failed to fetch data from ${url}`);
  }
};

/**
 * Fetches all images.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the list of images or rejects with an error.
 */
export const listImages = async (type) => {
  const url = `${baseUrl}/images`;
  return callApi(url, { method: 'GET' }, type);
};

/**
 * Processes an image.
 * @param {object} data - The image data to be processed.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the processed data or rejects with an error.
 */
export const processImage = async (data, type) => {
  const url = `${baseUrl}/images/process`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(url, options, type);
};

/**
 * Fetch images count.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the count of images or rejects with an error.
 */
export const countImages = async (type) => {
  const url = `${baseUrl}/images/count`;
  return callApi(url, { method: 'GET' }, type);
};

/**
 * Process Bulk.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export const bulkProcess = async (type) => {
  const url = `${baseUrl}/images/bulk/process`;
  return callApi(url, { method: 'GET' }, type);
};

/**
 * Submit Comment
 * @param {object} data - The comment data.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export const submitComment = async (data, type) => {
  const url = `${baseUrl}/images/comment`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return callApi(url, options, type);
};

/**
 * Upload And Process Images.
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export const uploadAndProcess = async (data, type) => {
  const url = `${baseUrl}/images/upload/process`;
  const options = {
    method: 'POST',
    body: data,
  };
  return callApi(url, options, type);
};

/**
 * Upload Images
 * @param {string} type - The type parameter.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export const uploadImagesData = async (data, type) => {
  const url = `${baseUrl}/images/upload`;
  const options = {
    method: 'POST',
    body: data,
  };
  return callApi(url, options, type);
};
