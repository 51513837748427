import React, {useState, useCallback} from 'react';
import {Loader} from "smart-react";
import {uploadImagesData} from '../Services/imageService';
import {useNotification} from '../../Core/Context/NotificationContext';
import {useDropzone} from 'react-dropzone';

/**
 * Component for uploading images.
 * @param {Function} onComplete - Callback function triggered when upload is complete.
 * @param {string} type - Type of the upload.
 * @param {string} title - Title for the upload section.
 * @returns {JSX.Element} UploadImages component.
 */
const UploadImages = ({onComplete, type, title = ""}) => {
    const {addNotification} = useNotification();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    /**
     * Callback function invoked when files are dropped.
     * @param {Array} acceptedFiles - Array of accepted files.
     */
    const onDrop = useCallback(acceptedFiles => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
        const nonImageFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));

        if (nonImageFiles.length > 0) {
            addNotification('error', 'Error: Only image files are allowed.');
        }

        setSelectedFiles(prevFiles => [...prevFiles, ...imageFiles]);
    }, []);

    /**
     * Handles the process of uploading images.
     */
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: 'image/*' // Accept only image files
    });
    const handleProcess = async () => {
        const formData = new FormData();
        selectedFiles.forEach(file => formData.append('files', file));
        setIsLoader(true);
        try {
            const response = await uploadImagesData(formData, type);
            if (response.isSuccess) {
                setSelectedFiles([]);
                if (onComplete) {
                    onComplete();
                }
                addNotification('success', 'Successfully Uploaded!');
            }
        } catch (error) {
            addNotification('error', 'Error submitting files: ' + error.message);
            console.error('Error submitting files:', error);
        } finally {
            setIsLoader(false);
        }
    };
    /**
     * Removes a file from the selected files.
     * @param {number} index - Index of the file to be removed.
     */
    const removeFile = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <div className="multi-image-upload k-pr-4 k-pl-4">
            {isLoader && <div className="loader-wrapper">
                <Loader/>
            </div>}
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-mt-2 k-mr-5 k-float-right"
                onClick={onComplete}
            >
                Back
            </button>
            <h6 className="k-p-4">Upload {title} Images</h6>
            <div className="k-p-5 dropzone k-mr-5 k-ml-5" {...getRootProps()}>
                <input {...getInputProps()} />
                <p className="k-text-center">Drag 'n' drop some files here, or click to select files</p>
            </div>
            <div className="uploaded-files" style={{display: 'flex', flexWrap: 'wrap'}}>
                {selectedFiles.map((file, index) => (
                    <div key={index} className="custom-card">
                        <div className="card-body">
                            <span className="file-name">{file.name}</span>
                            <button className="delete-button k-button-icon k-font-icon k-i-trash"
                                    onClick={() => removeFile(index)}/>
                        </div>
                    </div>
                ))}
            </div>
            <div className="k-p-4 k-text-right">
                <button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-mt-2"
                    onClick={handleProcess}
                    disabled={isLoader || selectedFiles.length === 0}
                >
                    {isLoader ? 'Uploading...' : 'Upload'}
                </button>
            </div>
        </div>
    );
};

export default UploadImages;
