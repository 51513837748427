import React, {useEffect} from 'react';
import {Notification, NotificationGroup} from '@progress/kendo-react-notification';
import {Fade} from '@progress/kendo-react-animation';
import {useNotification} from './../../Context/NotificationContext';

const SmartNotification = () => {
    const {notifications, removeNotification} = useNotification();

    useEffect(() => {
        // Automatically remove notifications after a certain duration
        const timeoutIds = notifications.map(notification => setTimeout(() => removeNotification(notification.id), 3000));

        return () => {
            // Clear all timeouts when the component unmounts
            timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
        };
    }, [notifications, removeNotification]);
    return (
        <NotificationGroup style={{right: 0, right: 0, alignItems: 'flex-end'}}>
            {notifications.map((notification) => (
                <div className="customSlideInRight" duration={1000}
                     key={notification.id}>
                    <Notification
                        type={{style: notification.type, icon: true}}
                        closable={true}
                        onClose={() => removeNotification(notification.id)}
                    >
                        <span>{notification.message}</span>
                    </Notification>
                </div>
            ))}
        </NotificationGroup>
    );
};

export default SmartNotification;
