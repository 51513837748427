import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = (type, message) => {
        const newNotification = {
            type,
            message,
            id: Date.now(),
        };
        setNotifications([...notifications, newNotification]);
    };

    const removeNotification = (id) => {
        setNotifications(notifications.filter((notification) => notification.id !== id));
    };

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};
