import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from 'smart-react';
import SmartGallery from '../../Core/Components/SmartGallery/SmartGallery';
import UploadImages from '../Componenets/UploadImages';
import MultiProcessedData from '../Componenets/MultiProcessedData';
import './RoadDefects.scss';
import {Loader} from 'smart-react';
import {listImages, processImage} from './../Services/imageService';
import {WAREHOUSE} from '../../../constants/applicationConstants';
import {useNotification} from './../../Core/Context/NotificationContext';

/**
 * Component for managing warehouse images.
 * @returns {JSX.Element} Warehouse component.
 */
const Warehouse = () => {
    const [isLoader, setIsLoader] = useState(false);
    const [processedData, setProcessedData] = useState(null);
    const [images, setImages] = useState({
        approved: [],
        rejected: [],
        raw: []
    });
    const {addNotification} = useNotification();
    const [isUploadVisible, setIsUploadVisible] = useState(false);
    /**
     * Fetches the photos from the server.
     */
    const fetchPhotos = useCallback(async () => {
        setIsLoader(true);
        try {
            const response = await listImages(WAREHOUSE);
            const {payload, isSuccess} = response;
            if (isSuccess) {
                const approved = payload.filter(image => image.status === 'approved');
                const rejected = payload.filter(image => image.status === 'rejected');
                const raw = payload.filter(image => !image.status);
                setImages({approved, rejected, raw});
                setIsUploadVisible(false);
            } else {
                // Handle error here, if needed
                console.error('Error fetching data:', response.error);
                addNotification('error', 'Error fetching data:' + response.error);
            }
        } catch (error) {
            handleFetchError(error);
        } finally {
            setIsLoader(false);
        }
    }, []);
    /**
     * Handles the fetch error.
     * @param {Error} error - The error object.
     */
    const handleFetchError = (error) => {
        console.error('Error fetching data:', error);
        addNotification('error', 'Error fetching data:' + error);
    };
    /**
     * Processes the selected images.
     * @param {Array} images - The images to be processed.
     */
    const handleProcessImage = useCallback(async (images) => {
        setIsLoader(true);
        try {
            const response = await processImage({images}, WAREHOUSE);
            const {payload, isSuccess} = response;
            if (isSuccess) {
                setProcessedData(payload);
                addNotification('success', 'Image Processed Successfully!');
            } else {
                // Handle error here, if needed
                console.error('Error processing image:', response.error);
                addNotification('error', 'Error processing image:' + response.error);
            }
        } catch (error) {
            handleProcessError(error);
        } finally {
            setIsLoader(false);
        }
    }, []);
    /**
     * Handles the process error.
     * @param {Error} error - The error object.
     */
    const handleProcessError = (error) => {
        console.error('Error processing image:', error);
        addNotification('error', 'Error processing image:' + error);
    };

    useEffect(() => {
        fetchPhotos();
    }, []);
    /**
     * Handles the back button click.
     */
    const handleBackClick = useCallback(() => {
        setProcessedData(null);
        fetchPhotos();
    }, [fetchPhotos]);

    const filteredImages = useMemo(() => {
        const {approved, rejected, raw} = images;
        return {
            raw,
            approved,
            rejected,
        };
    }, [images]);
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className="k-mt-5">
                {isLoader && (
                    <div className="loader-wrapper">
                        <Loader/>
                    </div>
                )}
                {(!isUploadVisible && processedData || (processedData && processedData.isSuccess === 'true')) && (
                    <button
                        onClick={handleBackClick}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-float-right k-mr-5"
                    >
                        Back
                    </button>
                )}
                {!isUploadVisible && !processedData && (
                    <button
                        onClick={() => setIsUploadVisible(true)}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-float-right k-mr-6"
                    >
                        Upload Images
                    </button>
                )}
                {!isUploadVisible && !processedData && (
                    <h6 className="k-pl-5 k-pt-2 k-b-0 heading-border">The Warehouse Model Images List</h6>
                )}
                {!isUploadVisible && !processedData && (
                    <div className="smart-galleries k-p-4">
                        <>
                            {Object.entries(filteredImages).map(([key, photos]) => (
                                <SmartGallery
                                    key={key}
                                    photos={photos}
                                    title={key === 'approved' ? 'Approved Images' : key === 'rejected' ? 'Rejected Images' : 'Images To Be Processed'}
                                    onProcessImage={handleProcessImage}
                                    imageClick={true}
                                    select={key === 'approved' ? false : key === 'rejected' ? false : true}
                                    type="raw"
                                />
                            ))}
                        </>
                    </div>
                )}
                {isUploadVisible && <UploadImages onComplete={fetchPhotos} type={WAREHOUSE} title={"Warehouse"}/>}
                {!isUploadVisible && processedData && (
                    <MultiProcessedData imagesData={processedData} updatePhotos={fetchPhotos} type={WAREHOUSE}
                                        setProcessedData={setProcessedData}/>
                )}
            </div>
        </ErrorBoundary>
    );
};

export default Warehouse;
