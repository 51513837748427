import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import {
  ACCESS_TOKEN,
  SKIP_SPLASH,
} from '../../../../constants/applicationConstants';
import { useAuth } from '../../Context/AuthContext';
import RoutesItems from '../../../../Routes/RoutesItems';
import useMatchedRoute from '../../../../Utils/Routes/useMatchedRoute';

import { Loader,setItem,getItem  } from 'smart-react';
const Splash = React.lazy(() => import('../../../Login/Pages/Splash'));
/**
 * This component is user as middleware on routes
 * @returns {Splash} returns auth User
 */

const AuthGaurd = () => {
  const route = useMatchedRoute(RoutesItems);

  const { login, checkTokens, getTokensFromStorage } = useAuth();
  const [auth, setAuth] = useState(
    sessionStorage.getItem(ACCESS_TOKEN) ? true : false
  );
  /**
   * Check User is Authenticated or not
   */
  const AuthUser = async () => {
    // if valid id and access tokens in session
    const { accessToken } = getTokensFromStorage();
    const skipSplash = setItem(SKIP_SPLASH);

    if (typeof accessToken !== 'undefined' && accessToken !== null) {
      if (checkTokens()) {
        setAuth(true);
      } else {
        // trigger login
        login();
      }
    } else if (skipSplash) {
      login();
    }
  };

  useEffect(() => {
    // Sync process On page reload/Navigate.
    AuthUser();
  }, []);

  /**
   * if Auth gaurd is Active on route and user is authenticated then it will open the requested component otherwise it will redirect to login splash scree
   * and if Auth gaurd is not Active then it will open the requested component with authenticate the user.
   */
    if (route?.isauth) {
      if (!auth) {
    return (
      <React.Suspense fallback={<Loader />}>
        <Splash />
      </React.Suspense>
    );
    }
  }
  return <Outlet />;
};

export default AuthGaurd;
