import React from 'react';
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from '@progress/kendo-react-form';

import { FormInput,MessageAlert,emailValidator, passwordValidator } from 'smart-react';
import { useAuth } from '../../Core/Context/AuthContext';
import { EXCEPTION_STATUS_CODES } from '../../../constants/applicationConstants';
import { callApi } from './../../../Utils/Api/Api';
import { ACCESS_TOKEN,REFRESH_TOKEN,TOKEN_EXP,USER_DATA } from '../../../constants/applicationConstants';
import './Splash.scss';
const dashboardPath = "/";
const LoginForm = ({setIsLoader}) => {
    const [formState, setFormState] = React.useState({});
    const { updateUser, checkTokens } = useAuth();
    const [alertMessage, setAlertMessage] = React.useState({
        alert: false,
        message: null,
    });
    React.useEffect(() => {
        if (checkTokens()) {
            window.location.replace(dashboardPath);
        }
    }, []);

    const handleSubmit = async (dataItem) => {
        setIsLoader(true);
        dataItem = {
            ...dataItem,
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            device_id: navigator.userAgent,
            browser: navigator.userAgent,
            os: navigator.platform,
        };
        try {
            let response = await callApi('/login', 'POST', dataItem);
            if (response?.isSuccess) {
                const data = response.payload;
                sessionStorage.setItem(ACCESS_TOKEN, data.token);
                sessionStorage.setItem(USER_DATA, JSON.stringify(data));

                updateUser(data);
                window.location.replace(dashboardPath);
            } else {
                if (
                    response?.statusCode?.code &&
                    !EXCEPTION_STATUS_CODES.includes(response?.statusCode?.code)
                ) {
                    setAlertMessage({ alert: true, message: response?.message });
                }
            }
        } catch (e) {
            console.log(e);
        }
        finally {
            setIsLoader(false);
        }
    };
    return (
        <>
            {alertMessage?.alert && (
                <MessageAlert
                    type={'danger'}
                    message={alertMessage?.message}
                    isIcon={true}
                    iconName={'error'}
                />
            )}

            <Form
                initialValues={formState}
                onSubmit={handleSubmit}
                render={(formRenderProps) => (

                    <FormElement>
                        <div>
                            <Field
                                key={'email'}
                                id={'email'}
                                name={'email'}
                                label={'Email'}
                                labelClass={"k-font-bold"}
                                inputClass={'k-login-input'}
                                type={'email'}
                                component={FormInput}
                                validator={emailValidator}
                                optional={false}
                            />
                            <Field
                                key={'password'}
                                id={'password'}
                                name={'password'}
                                label={'Password'}
                                labelClass={"k-font-bold"}
                                inputClass={'k-login-input'}
                                type={'password'}
                                component={FormInput}
                                validator={passwordValidator}
                                optional={false}
                            />
                        </div>
                        <div
                            style={{
                                justifyContent: "space-between",
                                alignContent: "center",
                            }}
                            className={
                                "my-test k-form-buttons k-button k-button-md k-rounded-md "
                            }
                        >
                            <Button className="k-w-100 ai-button-primary" type={"submit"}
                                    rounded={'medium'} size={'medium'}>
                                Login
                            </Button>
                        </div>
                    </FormElement>
                )}
            />
        </>
    );
}
export default LoginForm;
