import React, {useState, useEffect} from 'react';
import {Button} from '@progress/kendo-react-buttons';
import {TextArea} from "@progress/kendo-react-inputs";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {Card, CardHeader, CardTitle, CardBody} from "@progress/kendo-react-layout";
import {submitComment} from '../Services/imageService';
import {Loader} from "smart-react";
/**
 * Component for displaying and managing multi-processed images.
 * @param {Object[]} imagesData - Array of processed images data.
 * @param {Function} updatePhotos - Function to update photos after processing.
 * @param {string} type - Type of images.
 * @param {Function} setProcessedData - Function to set processed data.
 * @returns {JSX.Element} MultiProcessedData component.
 */
const MultiProcessedData = ({imagesData, updatePhotos, type, setProcessedData}) => {
    // Store images prop in a local variable
    const [images, setImages] = useState(imagesData);
    const [comments, setComments] = useState({});
    const [processedImages, setProcessedImages] = useState({});
    const [commentPopupVisible, setCommentPopupVisible] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        setImages(images); // Update localImages when images prop changes
    }, [images]);

    useEffect(() => {
        checkAllProcessed();
    }, [processedImages]); // Check if all images are processed whenever processedImages state changes
    /**
     * Handles the comment status of an image.
     * @param {string} id - The ID of the image.
     * @param {string} status - The status of the comment.
     */
    const handleCommentStatus = async (id, status) => {
        if (status === 'rejected') {
            setSelectedImageId(id);
            setCommentPopupVisible(true);
        } else {
            await updateCommentStatus(id, status);
        }

    };
    /**
     * Handles the submission of a comment.
     */
    const handleCommentSubmit = async () => {
        await updateCommentStatus(selectedImageId, 'rejected');
        setComments(prevComments => ({...prevComments, [selectedImageId]: ''}));
        setCommentPopupVisible(false);
    };
    /**
     * Updates the status of a comment.
     * @param {string} id - The ID of the image.
     * @param {string} status - The status of the comment.
     */
    const updateCommentStatus = async (id, status) => {
        setIsLoader(true);
        setCommentPopupVisible(false);
        try {
            const response = await submitComment({id, comment: comments[id], status});
            if (response.isSuccess) {
                setProcessedImages(prevProcessedImages => ({
                    ...prevProcessedImages,
                    [id]: true
                }));
                const updatedImages = images.filter(image => image.id !== id);
                setImages(updatedImages);
            }
            setIsLoader(false)
        } catch (error) {
            console.error('Error updating comment status:', error);
            setIsLoader(false)
        }
    };
    /**
     * Checks if all images are processed.
     */
    const checkAllProcessed = () => {
        const allProcessed = images.every(image => processedImages[image.id]);
        if (allProcessed) {
            setProcessedData(null);
            updatePhotos();
        }
    };
    /**
     * Handles the click event on an image.
     * @param {Object} image - The image object.
     */
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <div className="multi-image-upload">
            {isLoader && <div className="loader-wrapper">
                <Loader/>
            </div>}
            <h6 className="k-p-4">Processed Images</h6>
            {images?.length > 0 &&
            <div className="k-p-5 k-m-5 multi-container k-border k-border-solid">
                {images.map(image => (
                    <div key={image.id} className="multi-image-card k-m-5 multi-container-item">
                        <Card>
                            <CardHeader className="k-hbox">
                                <div>
                                    <CardTitle>
                                        <p className="k-m-0 k-p-0">Processed Image</p>
                                    </CardTitle>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <img src={image.result_file_path} className="card-image" alt="Result"
                                     style={{cursor: 'pointer', maxWidth: '100%', height: 'auto', borderRadius: '5px'}}
                                     onClick={() => handleImageClick(image)}/>
                            </CardBody>
                            <div className="k-mb-3 k-text-center">
                                <Button className="k-button-solid-primary k-m-3"
                                        onClick={() => handleCommentStatus(image.id, 'approved')}>
                                    <span className="k-button-icon k-font-icon k-i-check"></span> Approve
                                </Button>
                                <Button className="k-button k-button-md k-rounded-md k-button-solid reject-button k-m-3"
                                        onClick={() => handleCommentStatus(image.id, 'rejected')}>
                                    <span className="k-button-icon k-font-icon k-i-x"></span> Reject
                                </Button>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>
            }
            {commentPopupVisible && (
                <Dialog title="Reject Image with Comment" onClose={() => setCommentPopupVisible(false)}
                        visible={commentPopupVisible}>
                    <CardBody>
                        <TextArea value={comments[selectedImageId] || ''}
                                  onChange={(e) => setComments(prevComments => ({
                                      ...prevComments,
                                      [selectedImageId]: e.target.value
                                  }))}
                                  placeholder="Add comment"/>
                    </CardBody>
                    <DialogActionsBar>
                        <Button className="k-button-solid-primary k-mt-2" onClick={handleCommentSubmit}>Submit</Button>
                    </DialogActionsBar>
                </Dialog>
            )}
            {selectedImage && (
                <Dialog title="Image Details" onClose={() => setSelectedImage(null)} visible={true}>
                    <CardBody>
                        <img src={selectedImage.result_file_path} alt="Large Result" style={{maxWidth: '100%'}}/>
                        <h6>Processing Details:</h6>
                        {selectedImage?.tag_counts && (
                            <ul>
                                {Object.entries(selectedImage.tag_counts).map(([key, value]) =>
                                    <li key={key}>{key}: {value}</li>
                                )}
                            </ul>
                        )}
                        {selectedImage?.comment && selectedImage.comment !== undefined && (
                            <>
                                <h6 className="k-text-left">Comment</h6>
                                <p className="k-text-left k-ml-4">{selectedImage.comment}</p>
                            </>
                        )}
                    </CardBody>
                </Dialog>
            )}
        </div>
    );
};

export default MultiProcessedData;
