import React, {useState} from 'react';
import {Loader} from 'smart-react';
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout';

function ActivityDetection() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0); // Set the default selected tab index
    const [isPlaying, setIsPlaying] = useState(false); // State to control video playback

    const handleSelect = (e) => {
        setSelectedTab(e.selected);
    };

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    return (
        <>
            {isLoading && (
                <div className="loader-wrapper">
                    <Loader/>
                </div>
            )}
            <div className="multi-image-upload">
                <h6 className="k-p-4 k-pb-2 highlighted-heading heading-border">Activity Detection (Single & Multiple
                    Angle Camera)</h6>
                <TabStrip selected={selectedTab} onSelect={handleSelect}>
                    <TabStripTab title="Single Angle" contentClassName="k-text-center">
                        <div className="video-container">
                            <video
                                width="800"
                                height="450"
                                controls
                                onPlay={handlePlay}
                                onPause={handlePause}
                            >
                                <source
                                    src="https://smartisdev.blob.core.windows.net/ois-ai-models-app/AiVideos/Single%20angle%20-%20Table%20Tennis%20Area.mp4?sp=r&st=2024-04-02T14:20:14Z&se=2025-04-02T22:20:14Z&spr=https&sv=2022-11-02&sr=b&sig=xfs605ezYEVGJV%2BFHlkMHj9gAp5ojz2X6d1g2egQGh8%3D"
                                    type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </TabStripTab>
                    <TabStripTab title="Multiple Angle" contentClassName={"k-text-center"}>
                        <div className="video-container">
                            <video
                                width="800"
                                height="450"
                                controls
                                onPlay={handlePlay}
                                onPause={handlePause}
                            >
                                <source
                                    src="https://smartisdev.blob.core.windows.net/ois-ai-models-app/AiVideos/Multiple%20angles%20-%20Hall%20Area.mp4?sp=r&st=2024-04-02T14:24:51Z&se=2025-04-02T22:24:51Z&spr=https&sv=2022-11-02&sr=b&sig=K40%2FEElUoqsCqJLojZJ5MAvOv6suitxZIz1cXX4mKKQ%3D"
                                    type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </TabStripTab>
                </TabStrip>
            </div>
        </>
    );
}

export default ActivityDetection;
