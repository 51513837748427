import * as React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback, Loader} from 'smart-react';

/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const Splash = () => {
    const [isLoader, setIsLoader] = React.useState(false);
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <>
                {isLoader && <div className="loader-wrapper">
                    <Loader/>
                </div>}
                <>
                    <div>
                        <div>
                            <h5 className="k-p-4 k-m-0 heading-border">Welcome To Smart AI</h5>
                            <iframe className="reportClass k-border-0"
                                    src="https://app.powerbi.com/view?r=eyJrIjoiZTQxZDQwZDItNmUzZS00NzMzLTgxNzMtOGVlNTM1MzdlODVmIiwidCI6Ijg5YjgwYjdkLTdlMzAtNDhmNS1hMjA5LTMwYjdmNmFhOWVkNSIsImMiOjN9"
                            >
                            </iframe>

                        </div>
                    </div>
                </>
            </>
        </ErrorBoundary>
    );
};

export default Splash;
